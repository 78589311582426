import { ControllerParams } from 'yoshi-flow-editor-runtime';
import { getSettingsValues, IWixStyleParams } from 'yoshi-flow-editor-runtime/tpa-settings';
import isMultiLocationSite from '@wix/wixrest-utils/dist/isMultiLocationSite';
import { appName } from '../../../.application.json';
import {
  getOrganizationFullByAppDefAndInstance,
  getOrganizationFullByMsid,
  getPaymentMethods,
} from '../../core/oloApi';
import { AppState, createConfiguredStore } from '../../state/createStore';
import {
  setOrganizationFull,
  initApp,
  setPaymentMethods,
  setSignedInstance,
  setIsLocationPicked,
  setIsMultiLocation,
  setLoadingPaymentMethods,
  setLocations,
  setEssentialsExperiments,
} from '../../state/session/session.actions';
import {
  setPlatformParams,
  setPlatformParamsSettings,
  setPlatformParamsLabelsStyles,
} from '../../state/platformParams/platformParams.actions';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';
import { Store } from 'redux';
import { extractInstanceData } from '../../core/logic/instanceLogic';
import { getBaseUrlForMappedServices } from '../../core/logic/urlLogic';
import { componentSettings } from './componentSettings';
import uuid from 'uuid';
import WixInstance from '@wix/wixrest-utils/dist/WixInstance';
import {
  Action,
  Address,
  Contact,
  CouponSuccess,
  Dispatch,
  Instance,
  OrderItem,
  LabelsSettings,
  getOrganizationAndMenu,
  getDisplayableMenu,
  getSeoRestaurant,
  Restaurant,
  Menu,
  Location,
  extractLocalizedString,
  fetchRestaurant,
} from '@wix/restaurants-client-logic';
import { IWixWindowViewMode } from '@wix/native-components-infra/dist/es/src/types/types';
import { SESSION_STORAGE_KEY } from '../../core/constants';
import { CheckoutStep } from '../../core/types/Checkout';
import _ from 'lodash';
import { loadStoredDataIntoStore } from '../../core/logic/sessionStorageDataHandler';
import moment from 'moment-timezone';

export const ORGANIZATION_FULL_WARMUPDATA_KEY = 'RESTAURANT_ORGANIZATION_FULL';
const SESSION_ID = uuid.v4();

interface OrganizationFull {
  restaurant: Restaurant;
  menu: Menu;
  locations: Location[];
}

const getFromWarmUpData = (
  key: typeof ORGANIZATION_FULL_WARMUPDATA_KEY,
  flowAPI: ControllerFlowAPI,
): OrganizationFull | undefined => {
  return (
    flowAPI.controllerConfig.wixCodeApi.window.warmupData &&
    flowAPI.controllerConfig.wixCodeApi.window.warmupData.get(key) &&
    JSON.parse(flowAPI.controllerConfig.wixCodeApi.window.warmupData.get(key))
  );
};
const trySaveWarmupData = async (key: string, flowAPI: ControllerFlowAPI, data: any) => {
  const warmupData = flowAPI.controllerConfig.wixCodeApi.window.warmupData;
  if (
    flowAPI.controllerConfig.wixCodeApi.window.rendering.env === 'backend' &&
    (await flowAPI.getExperiments()).enabled('specs.restaurants.warmupData')
  ) {
    warmupData && warmupData.set(key, JSON.stringify(data));
  }
};

export interface EnvironmentData {
  language: string;
  store: Store<AppState, Action<any>>;
}

export interface StorageData {
  orderItems?: OrderItem[];
  coupon?: CouponSuccess;
  comment?: string;
  checkoutStep: CheckoutStep;
  contact: Contact;
  dispatch: Dispatch;
  selectedAddressOption: Address;
  timestamp: number;
  loyaltyPointsToRedeem?: number;
  selectedAddressId?: string;
  deliveryProvider?: { configurationId: string; fee: number; estimateId: string };
}

async function getOrganizationFull(
  metaSiteId: string,
  instanceId: string,
  appId: string,
  baseUrlForMappedServices: string,
  language: string,
) {
  let organizationFull;
  if (!!instanceId && !!appId && appId === WixInstance.ORDERS_APP_ID) {
    // if appId equals to orders app guid (olo-client case) use the new api, o.w- use the old one
    organizationFull = await getOrganizationFullByAppDefAndInstance(instanceId, appId, language);
  } else {
    organizationFull = await getOrganizationFullByMsid(metaSiteId, baseUrlForMappedServices);
  }
  if (organizationFull) {
    organizationFull = { ...organizationFull, locations: [] };
  }
  return organizationFull;
}

export async function getPaymentMethodsAndSetLoading(
  appDefId: string,
  instanceId: string,
  locale: string,
  setLoading: (loading: boolean) => void,
) {
  setLoading(true);
  return getPaymentMethods(appDefId, instanceId, locale);
}

async function setup(flowAPI: ControllerFlowAPI): Promise<EnvironmentData> {
  const language = flowAPI.getSiteLanguage();
  const experiments = await flowAPI.getExperiments();
  const { metaSiteId, instanceId, appDefId: appId } = extractInstanceFromFlowAPI(flowAPI);
  const baseUrlForMappedServices = getBaseUrlForMappedServices({
    websiteUrl: flowAPI.controllerConfig.wixCodeApi.location.baseUrl,
    environment: flowAPI.environment,
  });
  const locationId = flowAPI.controllerConfig.wixCodeApi.location.query?.locationId;
  const store = createConfiguredStore(undefined, { flowAPI });

  store.dispatch(setIsLocationPicked({ value: locationId ? true : false }));

  let organizationFull = getFromWarmUpData(ORGANIZATION_FULL_WARMUPDATA_KEY, flowAPI);

  if (!organizationFull) {
    const startTime = Date.now();
    let methodType =
      experiments.enabled('specs.restaurants.olo-fetch-restaurant-api-locations-first') &&
      experiments.enabled('specs.restaurants.olo-fetch-restaurant-api')
        ? 'v2.1-locations-first'
        : 'v2.1';

    organizationFull = await fetchRestaurantIfExperimentIsOpen(flowAPI, baseUrlForMappedServices, locationId);

    if (!organizationFull) {
      methodType = 'nodejs';
      flowAPI.fedopsLogger.interactionStarted('get-organization-and-menu');
      organizationFull = (await getOrganizationAndMenu(getSignedInstance(flowAPI), locationId)) as
        | OrganizationFull
        | undefined;
      flowAPI.fedopsLogger.interactionEnded('get-organization-and-menu');
    }

    flowAPI.biLogger &&
      flowAPI.biLogger.restaurantsNetworkPerf({
        methodType,
        isSsr: flowAPI.environment.isSSR,
        totalTime: Date.now() - startTime,
        oloSessionId: SESSION_ID,
      });

    // last resort
    if (!organizationFull && !experiments.enabled('specs.restaurants.MappingRestaurant')) {
      organizationFull = await getOrganizationFull(metaSiteId, instanceId, appId, baseUrlForMappedServices, language);
    }

    // it could happend is some very rare situations
    if (!organizationFull) {
      throw new Error('CANNOT FETCH ORGANIZATION!');
    }

    // save organization full to cache, we should not wait for it.
    trySaveWarmupData(ORGANIZATION_FULL_WARMUPDATA_KEY, flowAPI, organizationFull);
  }

  const locale = organizationFull.restaurant.locale || 'en_US';
  const paymentMethods = !experiments.enabled('specs.restaurants.delaySetPayments')
    ? await getPaymentMethodsAndSetLoading(appId, instanceId, locale, (loading) =>
        store.dispatch(setLoadingPaymentMethods({ loading })),
      )
    : undefined;

  const data = flowAPI.controllerConfig.platformAPIs.storage.session.getItem(SESSION_STORAGE_KEY);
  loadStoredDataIntoStore(
    store,
    data,
    organizationFull.menu,
    organizationFull.restaurant,
    flowAPI.environment.isMobile,
  );

  let isSingleLocationExperimentEnabled = false;

  try {
    isSingleLocationExperimentEnabled = flowAPI.controllerConfig.essentials.experiments.enabled(
      'specs.restaurants.single-location-new-live-site',
    );
  } catch (e) {}

  const hasMultipleLocations =
    isMultiLocationSite(organizationFull.restaurant) && organizationFull.locations.length > 1;
  const allowNewFlow =
    isSingleLocationExperimentEnabled && !experiments.enabled('specs.restaurants.default-fulfillment');

  store.dispatch(
    setIsMultiLocation({
      value: hasMultipleLocations || allowNewFlow,
    }),
  );
  store.dispatch(
    setEssentialsExperiments({
      essentialsExperiments: flowAPI.controllerConfig.essentials?.experiments.all(),
    }),
  );

  // in case the restaurant is not migrated to ML, but we want to show ML flow using specs.restaurants.single-location-new-live-site experiment
  // we need to mimic a location using our restaurant object.
  // - add currentLocationId property for the restaurant object for it to be a valid location
  // - set the restaurant object as one of the locations
  if (!isMultiLocationSite(organizationFull.restaurant) && allowNewFlow && organizationFull) {
    const DEFAULT_LOCATION_ID = '720c9e18-0a78-4036-a223-85a52b8eda29'; // this id is an arbitrary valid uuid
    const restaurant = organizationFull.restaurant;
    restaurant.currentLocationId = DEFAULT_LOCATION_ID;
    organizationFull.locations = [
      {
        isDefault: true,
        locationId: DEFAULT_LOCATION_ID,
        name: extractLocalizedString(restaurant.title, restaurant.locale),
      },
    ];
    store.dispatch(setLocations({ locations: [{ ...restaurant, currentLocationId: DEFAULT_LOCATION_ID }] }));
  }
  paymentMethods && store.dispatch(setPaymentMethods({ paymentMethods }));
  store.dispatch(setOrganizationFull({ organizationFull }));
  paymentMethods !== undefined && store.dispatch(setLoadingPaymentMethods({ loading: false }));
  store.dispatch(initApp());

  return {
    language,
    store,
  };
}
function getViewMode(flowAPI: ControllerFlowAPI): IWixWindowViewMode {
  if (flowAPI.environment.isEditor) {
    return 'Editor';
  } else if (flowAPI.environment.isPreview) {
    return 'Preview';
  } else {
    return 'Site';
  }
}

function getLabelsSettings(styleParams: IWixStyleParams, defaultLabelsSettings?: LabelsSettings): LabelsSettings {
  if (_.isEmpty(styleParams.numbers) && _.isEmpty(styleParams.colors) && defaultLabelsSettings) {
    return defaultLabelsSettings;
  }

  const numbersStyleParams = styleParams?.numbers;
  const iconTypeId = numbersStyleParams['wixorders.icontype'];
  const colorTypeId = numbersStyleParams['wixorders.colortype'];

  const labelsSettings: LabelsSettings = { iconTypeId, colorTypeId };
  const colorsStyleParams = styleParams?.colors;
  if (colorsStyleParams) {
    labelsSettings.iconPrimaryColor = colorsStyleParams['wixorder.iconprimarycolor']?.value;
    labelsSettings.iconSecondaryColor = colorsStyleParams['wixorder.iconsecondarycolor']?.value;
    labelsSettings.iconCustomPrimaryColor = colorsStyleParams['wixorder.iconcustomprimarycolor']?.value;
    labelsSettings.iconCustomSecondaryColor = colorsStyleParams['wixorder.iconcustomsecondarycolor']?.value;
  }

  return labelsSettings;
}

function initializeStoreWithPlatformParams(store: Store<AppState, Action<any>>, flowAPI: ControllerFlowAPI) {
  const publicData = flowAPI.controllerConfig.config.publicData;
  const styleParams = flowAPI.controllerConfig.config.style.styleParams;
  store.dispatch(
    setPlatformParams({
      platformParams: {
        compId: flowAPI.controllerConfig.compId.toString(),
        compClassName: '',
        isMobile: flowAPI.environment.isMobile,
        instance: extractInstanceFromFlowAPI(flowAPI),
        settings: getSettingsValues(publicData, componentSettings, {
          isMobile: flowAPI.environment.isMobile,
        }),
        isRTL: flowAPI.environment.isRTL,
        viewMode: getViewMode(flowAPI),
        signedInstance: flowAPI.controllerConfig.appParams.instance,
        labelsSettings: getLabelsSettings(styleParams, store.getState().platformParams.labelsSettings),
        isEditorX: flowAPI.environment.isEditorX,
      },
    }),
  );
}

function getSignedInstance(flowAPI: ControllerFlowAPI) {
  return (
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(WixInstance.ORDERS_APP_ID) ||
    flowAPI.controllerConfig.appParams.instance
  );
}

async function fetchRestaurantIfExperimentIsOpen(
  flowAPI: ControllerFlowAPI,
  baseUrlForMappedServices: string,
  locationId: string | undefined,
) {
  const experiments = await flowAPI.getExperiments();
  let organizationFull: OrganizationFull | undefined;

  if (
    experiments.enabled('specs.restaurants.MappingRestaurant') ||
    experiments.enabled('specs.restaurants.olo-fetch-restaurant-api')
  ) {
    flowAPI.biLogger &&
      flowAPI.biLogger.fetchOrganizationData({
        locationGuid: locationId,
        oloSessionId: SESSION_ID,
        retryNumber: 0,
        timestampMs: Date.now(),
        viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      });

    if (experiments.enabled('specs.restaurants.olo-fetch-restaurant-api-locations-first')) {
      flowAPI.fedopsLogger.interactionStarted('fetch-restaurant-locations-first');
      try {
        organizationFull = await fetchRestaurant(getSignedInstance(flowAPI), locationId, {
          host: baseUrlForMappedServices,
          preloadMenus: true,
          locationsFirst: true,
          retry: {
            retries: flowAPI.environment.isEditor ? 3 : 0,
            onRetry: (_error, attempt) =>
              flowAPI.biLogger?.fetchOrganizationData({
                locationGuid: locationId,
                oloSessionId: SESSION_ID,
                retryNumber: attempt,
                timestampMs: Date.now(),
                viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
              }),
          },
        });
      } catch {
        organizationFull = undefined;
      }
      flowAPI.fedopsLogger.interactionEnded('fetch-restaurant-locations-first');
    } else {
      flowAPI.fedopsLogger.interactionStarted('fetch-restaurant');
      try {
        organizationFull = await fetchRestaurant(getSignedInstance(flowAPI), locationId, {
          host: baseUrlForMappedServices,
          preloadMenus: true,
          retry: {
            retries: flowAPI.environment.isEditor ? 3 : 0,
            onRetry: (_error, attempt) =>
              flowAPI.biLogger?.fetchOrganizationData({
                locationGuid: locationId,
                oloSessionId: SESSION_ID,
                retryNumber: attempt,
                timestampMs: Date.now(),
                viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
              }),
          },
        });
      } catch {
        organizationFull = undefined;
      }
      flowAPI.fedopsLogger.interactionEnded('fetch-restaurant');
    }

    flowAPI.biLogger &&
      flowAPI.biLogger.fetchOrganizationData({
        locationGuid: locationId,
        oloSessionId: SESSION_ID,
        restaurantId: organizationFull?.restaurant.id,
        timestampMs: Date.now(),
        viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      });
  }

  return organizationFull;
}

export function extractInstanceFromFlowAPI(flowAPI: ControllerFlowAPI): Instance {
  const signedInstance = flowAPI.controllerConfig.appParams.instance;
  return extractInstanceData(signedInstance);
}

export function controllerFactory(setupFunction: (flowAPI: ControllerFlowAPI) => Promise<EnvironmentData>) {
  return async function createController({ controllerConfig, flowAPI }: ControllerParams): Promise<IWidgetController> {
    const { setProps } = controllerConfig;
    const { isMobile } = flowAPI.environment;

    const { store, language } = await setupFunction(flowAPI);
    initializeStoreWithPlatformParams(store, flowAPI);

    function dispatch(action: Action<any>) {
      store.dispatch(action);
    }

    if (flowAPI.biLogger) {
      flowAPI.biLogger.util.updateDefaults({
        oloSessionId: SESSION_ID,
        restaurantId: store.getState().session.restaurant.id,
        viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
        locationGuid: store.getState().session.restaurant.currentLocationId,
        visitor_id: undefined, // reset default values from yoshi
        projectName: undefined,
        appName: undefined,
      });
    }

    store.subscribe(() => {
      try {
        setProps({
          appState: store.getState(),
        });
      } catch (e) {
        console.warn('Error in controller: ', e);
      }
    });

    return {
      async pageReady() {
        const experiments = await flowAPI.getExperiments();

        if (experiments.enabled('specs.restaurants.renderSEOTags')) {
          flowAPI.fedopsLogger.interactionStarted('render-time-seo-online-orders');

          const { menu, restaurant } = store.getState().session;

          const displayableMenu = getDisplayableMenu(
            menu,
            restaurant.locale,
            restaurant.currency,
            moment(),
            'web',
            'takeout',
            'catalog',
          );

          const baseUrl = flowAPI.controllerConfig.wixCodeApi.location.baseUrl;

          const onlineOrderPageUrl =
            `${baseUrl}${flowAPI.controllerConfig.wixCodeApi.site.currentPage.url || ''}` || '';
          const seoRestaurant = getSeoRestaurant(restaurant, displayableMenu, baseUrl, onlineOrderPageUrl);

          await flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
            itemType: 'RESTAURANTS_ORDER_PAGE',
            itemData: seoRestaurant,
          });
          flowAPI.fedopsLogger.interactionEnded('render-time-seo-online-orders');
        }

        setProps({
          appState: store.getState(),
          appName,
          language,
          mobile: isMobile,
          dispatch,
          shouldNotRenderMain:
            flowAPI.environment.isSSR &&
            flowAPI.controllerConfig.wixCodeApi.location.path.find((path) => path === 'checkout'),
          isRTL: flowAPI.environment.isRTL,
          basePath: `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}${
            flowAPI.controllerConfig.wixCodeApi.site.currentPage.url || ''
          }`,
          revalidateSession: async () => {
            // @ts-ignore
            await flowAPI.controllerConfig.wixCodeApi.site.loadNewSession();
            const newSignedInstance = flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(WixInstance.ORDERS_APP_ID);
            if (newSignedInstance) {
              dispatch(setSignedInstance({ signedInstance: newSignedInstance }));
              return newSignedInstance;
            }
          },
        });
      },
      updateConfig: ($w, updatedConfig) => {
        const updatedPublicData = updatedConfig.publicData.COMPONENT || {};
        const updatedSettings = getSettingsValues(updatedPublicData, componentSettings, {
          isMobile: flowAPI.environment.isMobile,
        });
        const updatedStyles = updatedConfig.style.styleParams || {};

        store.dispatch(
          setPlatformParamsSettings({
            settings: updatedSettings,
          }),
        );
        store.dispatch(
          setPlatformParamsLabelsStyles({
            labelsSettings: getLabelsSettings(updatedStyles),
          }),
        );
      },
    };
  };
}
export default controllerFactory(setup);
